import React from "react";
import { Box, Button, Container, Typography, TextField, CircularProgress } from "@mui/material";
import { AuthContainer, AuthWrapper } from "../styles";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import { loginSchema } from "../schemas";
import { useLoginMutation } from "../../../store/services/auth/auth.service";
import { toast } from "react-toastify";

const Login = () => {
	const navigate = useNavigate();
	const [callLogin, { isLoading }] = useLoginMutation();

	const {
		handleSubmit,
		handleChange,
		errors,
		values: { email, password },
		dirty,
		isValid
	} = useFormik({
		validationSchema: loginSchema,
		initialValues: {
			email: "",
			password: ""
		},
		onSubmit: values => {
			callLogin(values).then(({ data, error }) => {
				if (data) {
					navigate("/");
				}
				if (error) {
					toast.error((error as { data: { message: string } }).data.message, { autoClose: 5000 });
				}
			});
		}
	});

	return (
		<Container>
			<AuthContainer>
				<form onSubmit={handleSubmit}>
					<AuthWrapper>
						<Box className={"left_block"}>
							<Typography variant={"h6"} className={"title"}>
								Sign In
							</Typography>
							<Box mt={2} width={"100%"}>
								<Box mb={2} className={"input_wrapper"}>
									<Typography variant={"subtitle1"} fontWeight={"bold"}>
										Email
									</Typography>
									<TextField
										fullWidth
										type={"email"}
										value={email}
										name={"email"}
										onChange={handleChange}
										error={!!errors.email}
										helperText={errors.email}
									/>
								</Box>
								<Box mb={2} className={"input_wrapper"}>
									<Typography variant={"subtitle1"} fontWeight={"bold"}>
										Password
									</Typography>
									<TextField
										fullWidth
										type={"password"}
										value={password}
										name={"password"}
										onChange={handleChange}
										error={!!errors.password}
										helperText={errors.password}
									/>
								</Box>
							</Box>
							<Box width={"100%"}>
								<Button
									fullWidth
									variant={"contained"}
									type="submit"
									disabled={(dirty && !isValid) || isLoading}
									startIcon={isLoading && <CircularProgress size={10} />}
								>
									Sign In
								</Button>
							</Box>
							<Box width={"100%"} display={"flex"} justifyContent={"flex-end"}>
								<Button variant={"text"}>Forgot Password</Button>
							</Box>
						</Box>

						<Box className={"right_block"}>
							<Typography variant={"h4"}>Welcome to login</Typography>
							<Typography variant={"subtitle2"}>Don't have an account?</Typography>
							<Button variant={"contained"} size={"large"} onClick={() => navigate("/signup")}>
								Sign Up
							</Button>
						</Box>
					</AuthWrapper>
				</form>
			</AuthContainer>
		</Container>
	);
};

export default Login;
