import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from "../Header";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLazyFetchUserDataQuery } from "../../store/services/auth/auth.service";
import { useAppSelector } from "../../store";
import { getFullState } from "../../store/slices/auth/auth.slice";

const AppWrapper = () => {
	const localStorageToken = localStorage.getItem("token");
	const [callWhoami] = useLazyFetchUserDataQuery();
	const [loader, setLoader] = useState(true);
	const { isLoggedIn } = useAppSelector(getFullState);
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if (isLoggedIn) {
			switch (location.pathname) {
				case "/login": {
					return navigate("/");
				}
				case "/signup": {
					return navigate("/");
				}
				default: {
				}
			}
		}
	}, [isLoggedIn, location.pathname]);

	useEffect(() => {
		if (!isLoggedIn && localStorageToken) {
			callWhoami().then(() => {
				setLoader(false);
			});
		} else {
			setLoader(false);
		}
	}, [isLoggedIn, localStorageToken]);

	return (
		<ErrorBoundary fallback={<div style={{ background: "red" }}>Something went wrong</div>}>
			{loader ? (
				<Box display={"flex"} justifyContent={"center"} alignItems={"center"} height={"100vh"}>
					<CircularProgress />
				</Box>
			) : (
				<Box position={"relative"}>
					<Header />
					<Outlet />
				</Box>
			)}
			<ToastContainer position="bottom-right" hideProgressBar={true} theme="colored" />
		</ErrorBoundary>
	);
};

export default AppWrapper;
