import * as yup from "yup";

const REGEX: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
export const loginSchema = yup.object({
	email: yup.string().email("Email not valid").required("Email is required"),
	password: yup.string().required("Password is required")
});

export const signupSchema = yup.object({
	firstName: yup.string().required("First Name is required"),
	lastName: yup.string().required("Last Name is required"),
	email: yup.string().email("Email not valid").required("Email is required"),
	password: yup
		.string()
		.matches(REGEX, {
			message:
				"password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special"
		})
		.required("Password is required"),
	confirmPassword: yup
		.string()
		.required()
		.oneOf([yup.ref("password")], "Passwords must match")
});
