import React from "react";
import { Box, Typography } from "@mui/material";
import ComingSoon from "../../../assets/imgs/coming_soon.png";
import { round } from "lodash";

const WeddingList = ({
	templatesData
}: {
	templatesData: {
		id: number;
		discount: { isPercentage: boolean; amount: number } | null;
		coming_soon: boolean;
		thumbnail: string;
		name: string;
		price: number;
	}[];
}) => {
	return (
		<Box className={"templates_list"}>
			{templatesData.map(t => (
				<Box className={"template"} key={t.id}>
					{t.discount && (
						<Box className={"discount"}>
							<Typography variant={"subtitle1"}>
								Save {t.discount.isPercentage ? `${t.discount.amount}%` : `$ ${t.discount.amount}`}
							</Typography>
						</Box>
					)}
					{t.coming_soon && (
						<Box className={"coming_soon_over_lay"}>
							<img src={ComingSoon} alt="comming soon" />
						</Box>
					)}

					<img src={t.thumbnail} alt="template" className={"thumbnail"} />
					<Box mt={1}>
						<Typography variant={"h6"}>{t.name}</Typography>
					</Box>
					<Box mt={1} display={"flex"} alignItems={"center"} width={"100%"}>
						{t.discount ? (
							<Typography variant={"subtitle1"} className={"price"}>
								${round(t.price - (t.price * t.discount.amount) / 100, 2)}
							</Typography>
						) : (
							<Typography variant={"subtitle1"} className={"price"}>
								${round(t.price, 2)}
							</Typography>
						)}

						{t.discount && (
							<Typography variant={"subtitle2"} className={"old_price"}>
								${round(t.price, 2)}
							</Typography>
						)}
					</Box>
				</Box>
			))}
		</Box>
	);
};

export default WeddingList;
