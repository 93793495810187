import React from "react";

import { createBrowserRouter } from "react-router-dom";
// pages
import Landing from "./Pages/Landing";
import Signup from "./Pages/Auth/Signup";
import Login from "./Pages/Auth/Login";
//components
import AppWrapper from "./Components/AppWrapper";
import AuthRoute from "./Components/AuthRoute";

export const router = createBrowserRouter([
	{
		element: <AppWrapper />,
		children: [
			{
				path: "/",
				Component: Landing
			},
			{ path: "/signup", Component: Signup },
			{ path: "/login", Component: Login },
			{
				element: <AuthRoute />,
				children: [
					{
						path: "/test",
						Component: Landing
					}
				]
			}
		]
	}
]);
