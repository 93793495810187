import { createTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

declare module "@mui/material/styles" {
	interface Theme {
		custom_palette: {
			color1: string;
			color2: string;
			color3: string;
			color4: string;
		};
	}
	// allow configuration using `createTheme`
	interface ThemeOptions {
		custom_palette?: {
			color1?: string;
			color2?: string;
			color3?: string;
			color4?: string;
		};
	}
}

const theme = createTheme({
	typography: {
		fontFamily: ["Open Sans", "Montserrat"].join(",")
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					fontFamily: "Roboto",
					fontSize: "16px"
				}
			}
		},
		MuiInputBase: {
			styleOverrides: {
				input: {
					fontFamily: ["Open Sans", "Montserrat"].join(",")
				}
			}
		}
	},
	palette: {
		primary: {
			light: "#93bfe3",
			main: "#789DBC",
			dark: "#557189",
			contrastText: "#fff"
		},
		secondary: {
			light: "#f8b582",
			main: "#fdad73",
			dark: "#fd944d",
			contrastText: "#000"
		},
		text: {
			primary: "#000",
			secondary: grey[700],
			disabled: grey[500]
		}
	},
	custom_palette: {
		color1: "#789DBC",
		color2: "#FFE3E3",
		color3: "#FEF9F2",
		color4: "#C9E9D2"
	}
});

export default theme;
