import styled from "styled-components";
import { Box, alpha } from "@mui/material";
import { grey, green, deepOrange, common } from "@mui/material/colors";
// import background from "../../assets/imgs/bg.webp";
import curvedBackground from "../../assets/imgs/landing_curve.webp";

export const LandingContainer = styled(Box)`
	width: 100%;
	height: 100vh;
	background-image: url(${curvedBackground});
	background-repeat: no-repeat;
	background-size: 100vw 100vh;

	${props => props.theme.breakpoints.down("sm")} {
		height: 65vh;
		background-size: 100vw 65vh;
	}
`;

export const LandingOverlay = styled(Box)`
	background: rgb(120, 157, 188);
	background: linear-gradient(145deg, rgba(120, 157, 188, 0.9) 30%, rgba(255, 227, 227, 0.9) 70%);
	width: inherit;
	height: inherit;
`;

export const MainSectionFade = styled(Box)`
	position: absolute;
	background: linear-gradient(180deg, transparent, #ffffff);
	width: 100%;
	height: 60px;
	z-index: 1;
	bottom: 0;
`;

export const MainSection = styled(Box)`
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	h4 {
		font-family: var(--poppins);
		font-weight: bold;
		font-size: 46px;
		text-align: center;
	}

	h6 {
		font-family: var(--poppins);
		font-size: 18px;
		text-align: center;
	}
	.main_phone {
		width: 600px;
	}
	.phone_wrapper {
		margin-top: 100px;
		margin-bottom: 40px;
	}
	.t1 {
		display: inline-block;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 35px 0px 35px 60.6px;
		border-color: transparent transparent transparent ${({ theme }) => theme.custom_palette.color2};
		position: absolute;
		left: 20%;
	}
	.t2 {
		display: inline-block;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0px 160px 110px 0px;
		border-color: transparent ${({ theme }) => theme.custom_palette.color3} transparent transparent;
		position: absolute;
		left: -10%;
		top: 30%;
	}
	.t3 {
		display: inline-block;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0px 40px 60px 40px;
		border-color: transparent transparent ${({ theme }) => theme.custom_palette.color2} transparent;
		position: absolute;
		right: 10%;
	}
	.t4 {
		display: inline-block;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 20px 40px 20px 0px;
		border-color: transparent ${({ theme }) => theme.custom_palette.color3} transparent transparent;
		position: absolute;
		right: 38%;
		top: -15%;
	}
	.t5 {
		display: inline-block;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0px 0px 70px 70px;
		border-color: transparent transparent ${({ theme }) => theme.custom_palette.color3} transparent;
		position: absolute;
		right: 15%;
		top: 35%;
	}

	${props => props.theme.breakpoints.down("sm")} {
		height: 65vh;
		padding-top: 30px;
		h4 {
			font-size: 30px;
		}
		h6 {
			font-size: 14px;
		}
		.t1,
		.t2,
		.t3,
		.t3,
		.t4,
		.t5 {
			display: none;
		}
		.phone_wrapper {
			margin-top: 10px;
			margin-bottom: 10px;
		}
		.main_phone {
			width: 100%;
		}
	}
`;

export const TemplateSection = styled(Box)`
	min-height: 200px;
	padding: 150px 30px;

	.categories {
		display: flex;
		flex-flow: row;
		overflow: auto;
		padding: 5px;
	}

	.category {
		width: 100px;
		height: 100px;
		margin: 10px;
		display: flex;
		flex-flow: column;
		align-items: center;
		justify-content: center;
		padding: 10px 45px;
		border: 1px solid ${grey[200]};
		border-radius: 5px;
		cursor: pointer;
		transition: all 0.5s;
		.icon {
			margin-bottom: 15px;
			width: 30px;
			height: 30px;
		}
		&.active {
			background: ${({ theme }) => theme.custom_palette.color3};
			box-shadow: 0 0 3px 1px;
		}
		&:hover {
			box-shadow: 0 0 3px 1px;
		}
	}

	.templates_list {
		display: flex;
		flex-flow: row wrap;
		.template {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			margin: 15px;
			position: relative;
			width: 250px;
			.coming_soon_over_lay {
				position: absolute;
				background: ${({ theme }) => alpha(theme.custom_palette.color1, 0.7)};
				height: 450px;
				width: inherit;
				z-index: 1;
				display: flex;
				justify-content: center;
				align-items: center;
				img {
					width: 70%;
				}
			}
			.discount {
				position: absolute;
				top: 10px;
				right: -15px;
				background: ${deepOrange[600]};
				height: 40px;
				border-radius: 5px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 2px 10px;
				h6 {
					color: ${common.white};
				}
			}
			.thumbnail {
				width: inherit;
				height: 450px;
			}
			.price {
				font-size: 20px;
				font-weight: bold;
			}
			.old_price {
				font-size: 14px;
				position: relative;
				margin-left: 10px;
				&:before {
					position: absolute;
					top: 50%;
					content: "";
					height: 1px;
					width: 100%;
					background: ${({ theme }) => theme.palette.text.primary};
				}
				&:after {
					position: absolute;
					top: -10px;
					content: "%20 OFF";
					color: ${green[400]};
					width: 80px;
					font-size: 15px;
				}
			}
		}
	}
	${props => props.theme.breakpoints.down("sm")} {
		padding: 50px 10px;

		.category {
			height: 70px;
			.icon {
				margin-bottom: 5px;
			}
		}
		.templates_list {
			justify-content: center;
			.categories {
				.category {
					padding: 10px 30px;
				}
			}
			.template {
				width: 90%;
				.thumbnail {
					width: 100%;
					height: 500px;
				}
				.coming_soon_over_lay {
					width: 100%;
					height: 500px;
				}
			}
		}
	}
`;

export const WorksSection = styled(Box)`
	background: ${({ theme }) => theme.custom_palette.color3};
	margin-top: 40px;
	display: flex;
	align-items: center;
	.works_wrapper {
		display: flex;
		justify-content: space-between;
		flex-flow: row wrap;
		padding: 100px 40px;
		.works_image {
			width: 400px;
		}

		.title {
			font-weight: bold;
			text-align: center;
			font-family: var(--poppins);
			span {
				font-size: 40px;
				font-weight: bolder;
			}
		}
	}
	${props => props.theme.breakpoints.down("md")} {
		.works_wrapper {
			justify-content: center;
		}
	}
	${props => props.theme.breakpoints.down("sm")} {
		.works_wrapper {
			padding: 10px;
			.works_image {
				width: 90%;
			}
			.title {
				font-size: 24px;
				span {
					font-size: 28px;
				}
			}
			.right_block {
				margin-top: 40px;
			}
		}
	}
`;

export const FeaturesSection = styled(Box)`
	padding: 60px 40px;
	.title {
		font-family: var(--poppins);
		font-weight: bold;
		margin-bottom: 40px;
	}
	.features_wrapper {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
	}
	.feature {
		width: 250px;
		background: ${({ theme }) => theme.custom_palette.color2};
		display: flex;
		flex-flow: column;
		justify-content: center;
		align-items: center;
		padding: 15px;
		border-radius: 10px;
		margin: 20px;
		img {
			margin-top: 10px;
			margin-bottom: 20px;
		}
		h6:last-child {
			margin-top: 5px;
			height: 60px;
			width: 90%;
		}
	}
`;

export const WhoAreWeSection = styled(Box)`
	padding: 60px 40px;

	.title {
		font-family: var(--poppins);
		font-weight: bold;
		margin-bottom: 40px;
	}

	.who_are_we_wrapper {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		.who_description {
			font-family: var(--openSans);
			span {
				font-size: 28px;
				font-weight: bold;
			}
		}
		& > div {
			flex-basis: 48%;
		}
	}

	${props => props.theme.breakpoints.down("sm")} {
		padding: 10px;
		.who_are_we_wrapper {
			img {
				width: 300px;
			}
			& > div {
				flex-basis: 100%;
			}
			& div:first-child {
				margin-bottom: 30px;
			}
		}
	}
`;

export const FooterSection = styled(Box)`
	background: ${({ theme }) => theme.custom_palette.color3};
	min-height: 200px;

	.top_footer {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		padding-top: 25px;
		padding-bottom: 25px;
		.email {
			background: ${({ theme }) => theme.custom_palette.color2};
			cursor: pointer;
			padding: 5px 10px;
			border-radius: 4px;
			text-decoration: none;
		}
		.social {
			width: 20px;
			cursor: pointer;
		}
	}

	${props => props.theme.breakpoints.down("sm")} {
		.top_footer {
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 10px;
		}
		div:last-child {
			justify-content: center;
			margin-bottom: 16px;
		}
	}
`;
