import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import { useAppSelector } from "../../store";
import { getIsLoggedIn } from "../../store/slices/auth/auth.slice";

const AuthRoute = () => {
	const isLoggedIn = useAppSelector(getIsLoggedIn);

	return isLoggedIn ? <Outlet /> : <Navigate to="/login" replace />;
};

export default AuthRoute;
