import styled from "styled-components";
import { PersonOutline } from "@mui/icons-material";
import { Box } from "@mui/material";

export const HeaderWrapper = styled(Box)`
	width: 100%;
	position: absolute;
	top: 0;
	z-index: 1;
`;

export const StyledPerson = styled(PersonOutline)`
	path {
		fill: ${({ theme }) => theme.palette.text.primary};
	}
`;
