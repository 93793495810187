import { api } from "../index";
import { ILoginIn, ISignUp, User } from "./dto/auth.dto";

const BASE_AUTH_ENDPOINT = "/auth";
const BASE_USER_ENDPOINT = "/users";

export const authAPI = api.injectEndpoints({
	endpoints: build => ({
		signUp: build.mutation<{ user: User; token: string }, ISignUp>({
			query: body => ({
				url: `${BASE_AUTH_ENDPOINT}/signup`,
				method: "POST",
				body
			})
		}) as any,
		login: build.mutation<{ user: User; token: string }, ILoginIn>({
			query: body => ({
				url: `${BASE_AUTH_ENDPOINT}/login`,
				method: "POST",
				body
			})
		}) as any,
		fetchUserData: build.query<{ user: User; token: string }, void>({
			query: () => ({ url: `${BASE_USER_ENDPOINT}/whoami`, method: "GET" })
		})
	})
});

export const { useSignUpMutation, useLoginMutation, useLazyFetchUserDataQuery } = authAPI;
