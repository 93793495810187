import React from "react";
import { Box, Button, Typography, IconButton } from "@mui/material";

import VL_Logo from "../../assets/imgs/vl-logo-transparentBg.svg";
import { StyledPerson } from "./styles";

import { HeaderWrapper } from "./styles";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../store";
import { getFullState } from "../../store/slices/auth/auth.slice";
const Header = () => {
	const navigate = useNavigate();
	const { isLoggedIn } = useAppSelector(getFullState);

	return (
		<HeaderWrapper>
			<Box display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} p={2}>
				<Box
					display={"flex"}
					flexDirection={"row"}
					alignItems={"center"}
					onClick={() => navigate("/")}
					style={{ cursor: "pointer" }}
				>
					<img src={VL_Logo} alt="VL Logo" width={50} />
					<Box ml={1}>
						<Typography variant={"h6"} fontWeight={"bold"} color={"textPrimary"}>
							VitesLink
						</Typography>
					</Box>
				</Box>
				<Box>
					{isLoggedIn ? (
						<IconButton>
							<StyledPerson />
						</IconButton>
					) : (
						<Button variant={"contained"} color={"secondary"} onClick={() => navigate("/login")}>
							Login
						</Button>
					)}
				</Box>
			</Box>
		</HeaderWrapper>
	);
};

export default Header;
