import React from "react";
import { Box, Typography } from "@mui/material";

import Gear from "../../../assets/imgs/Gear.png";
import Language from "../../../assets/imgs/Language_Square.png";
import Chat from "../../../assets/imgs/Message_Chat.png";
import Location from "../../../assets/imgs/Location.png";
import Media from "../../../assets/imgs/media.png";
import Share from "../../../assets/imgs/Share.png";

const list = [
	{ title: "Fast Creation", icon: Gear, description: "Your invitation will be up and ready once published" },
	{ title: "Multi Language", icon: Language, description: "Arabic and English language are supported" },
	{ title: "RSVP", icon: Chat, description: "Users can respond to the invitation and leave comments" },
	{ title: "Location", icon: Location, description: "Provide precise location for the event" },
	{ title: "Media upload", icon: Media, description: "Photos and videos can be attached" },
	{ title: "Share", icon: Share, description: "Share invitation with others" }
];

const FeaturesList = () => {
	return (
		<Box display={"flex"} flexDirection={"column"}>
			<Box display={"flex"} justifyContent={"center"}>
				<Typography variant={"h4"} fontWeight={"bold"} className={"title"}>
					Features
				</Typography>
			</Box>
			<Box className={"features_wrapper"}>
				{list.map(l => (
					<Box className={"feature"} key={l.title}>
						<img src={l.icon} alt="Dashboard icon" width={30} />
						<Typography variant={"h6"} fontWeight={"bold"}>
							{l.title}
						</Typography>
						<Typography variant={"subtitle2"} textAlign={"center"}>
							{l.description}
						</Typography>
					</Box>
				))}
			</Box>
		</Box>
	);
};

export default FeaturesList;
